@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  height: 100px;
  width: 100px;
  text-align: center;
  border-radius: 50%;

  img {
    width: 100%;
  }
}
